$body-bg: #fafafa;
$primary: #3583ee !default;
$primary10: #e1eeff !default;
$primary20: #f6faff !default;
$secondary: #dce7f7 !default;
$bluelight: #dce7f7 !default;
$danger: #f86d6b !default;
$red: #f86d6b !default;
$success: #2eb85c;
$grey: #cdcdcd !default;
$font-size-base: 0.875rem !default;
$input-btn-font-size: $font-size-base * 1.02;
$box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.15);

//////////////////////////////////////////////////

// colors
$PRIMARY_0: #f5faff;
$PRIMARY_10: #e1eeff;
$PRIMARY_20: #bcd6f9;
$PRIMARY_30: #9ac1f6;
$PRIMARY_40: #78acf4;
$PRIMARY_50: #5798f1;
$PRIMARY_60: #3583ee;
$PRIMARY_70: #2c6dc6;
$PRIMARY_80: #23579f;
$PRIMARY_90: #1a4177;
$PRIMARY_100: #122c4f;

$SECONDARY_0: #f8fffd;
$SECONDARY_10: #e2fff8;
$SECONDARY_30: #b6ffee;
$SECONDARY_40: #9effe8;
$SECONDARY_60: #6effdd;
$SECONDARY_70: #5cd4b8;
$SECONDARY_90: #25554a;
$SECONDARY_100: #16332c;

$NEUTRAL_10: #ffffff;
$NEUTRAL_20: #f5f5f5;
$NEUTRAL_30: #ededed;
$NEUTRAL_40: #e0e0e0;
$NEUTRAL_50: #c2c2c2;
$NEUTRAL_60: #9e9e9e;
$NEUTRAL_70: #757575;
$NEUTRAL_80: #616161;
$NEUTRAL_90: #404040;
$NEUTRAL_100: #0a0a0a;

$RED_0: #fff8f8;
$RED_10: #ffecec;
$RED_20: #ffc2c2;
$RED_50: #f24242;
$RED_70: #ae1e1e;
$RED_80: #8c1111;
$RED_100: #480202;

$GREEN_0: #f4fffd;
$GREEN_10: #ccefe7;
$GREEN_20: #aae4d6;
$GREEN_40: #55caae;
$GREEN_50: #2abc99;
$GREEN_60: #00af85;
$GREEN_70: #00926f;
$GREEN_90: #005742;
$GREEN_100: #003a2c;

$YELLOW_0: #fffef8;
$YELLOW_10: #fffceb;
$YELLOW_20: #fff6bd;
$YELLOW_30: #ffef90;
$YELLOW_50: #ffe336;
$YELLOW_60: #ddc323;
$YELLOW_70: #bba414;
$YELLOW_90: #776600;

$PURPLE_10: #f9f5ff;
$PURPLE_20: #f4ebff;
$PURPLE_30: #e9d7fe;
$PURPLE_40: #d6bbfb;
$PURPLE_50: #b692f6;
$PURPLE_60: #9e77ed;
$PURPLE_70: #7f56d9;
$PURPLE_80: #6941c6;
$PURPLE_90: #53389e;
$PURPLE_100: #42307d;

// font weight
$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_MEDIUM: 500;
$FONT_WEIGHT_SEMI_BOLD: 600;
$FONT_WEIGHT_BOLD: 700;

// font size
$TEXT_EXTRA_SMALL: 0.75rem; //12px
$TEXT_SMALL: 0.875rem; // 14px
$TEXT_MEDIUM: 1rem; // 16px
$TEXT_LARGE: 1.125rem; //18px

$TITLE_SMALL: 1.265rem; //20px
$TITLE_MEDIUM: 1.5rem; //24px
$TITLE_LARGE: 1.8rem; //28px

$DISPLAY_SMALL: 2rem; //32px
$DISPLAY_MEDIUM: 3rem; //48px
$DISPLAY_LARGE: 4rem; //64px
