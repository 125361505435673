@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui-pro/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';

body {
  font-family: 'Inter', sans-serif !important;
}
