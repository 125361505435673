@import './variables';

// Here you can add other styles
::placeholder {
  color: $NEUTRAL_50 !important;
}
.form-control {
  &.grey {
    background-color: #fafafa;
  }
}
.input-group-append .input-group-text {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  background: transparent;
}
.input-group-prepend .input-group-text {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  background: transparent;
}
.table-card-wrapper {
  border: 2px solid #efefef;
  border-radius: 7px;
  margin-bottom: 25px;

  &.scroll {
    max-height: 350px;
    overflow: auto;
  }
}
.o-input,
.o-select {
  height: 44px;
  &:focus,
  &:active {
    box-shadow: none;
    outline: 0;
    border-color: #e4e7ea;
  }
}

.o-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: -1;
  display: none;
  &.open {
    opacity: 1;
    z-index: 11;
    display: block;
  }
}

.table-user-activity {
  .table {
    th {
      border: 0 !important;
      padding: 0 !important;
    }
    td {
      border: 0 !important;
    }
    tr {
      &:nth-child(odd) {
        td {
          background-color: $primary20 !important;
        }
      }
    }
  }
}

$width: 8px;

.o-select-dropdown {
  position: relative;

  &.disabled {
    .trigger {
      cursor: default;
      pointer-events: none;

      border-color: #d9d9d9 !important;
      background-color: #f5f5f5 !important;
      .trigger-text,
      .trigger-arrow {
        color: #00000040 !important;
      }
    }
  }

  .trigger {
    background-color: $primary10;
    border: 1px solid $primary10;
    width: 100%;
    color: $primary;
    border-radius: 5px;
    padding: 7px 35px 7px 15px;
    height: 40.56px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    max-width: 100%;
    .trigger-text {
      // max-width: 90%;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
    }
    .trigger-arrow {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-45%);
    }
  }

  ul {
    background-color: $white;
    position: absolute;
    box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
    top: 120%;
    right: 0;
    z-index: 100;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    transition: all 0.2s;
    min-width: 200px;
    display: block;
    max-height: 260px;
    overflow: auto;
    li {
      padding: 11px 30px 11px 15px;
      list-style: none;
      cursor: pointer;
      position: relative;
      margin: 0 auto;
      border-bottom: 1px solid #eee;
      &.disabled {
        background-color: white !important;
      }
      &:first-of-type {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      &:last-of-type {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border-bottom: 0;
      }
      &:hover {
        background-color: $primary10;
        border-color: $primary10;
      }
      .checked {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }

    .no-data {
      text-align: center;
      cursor: auto;
      &:hover {
        background-color: $white;
        border-color: $white;
      }
    }
  }
}

.table-wrapper {
  overflow-x: auto;
  .table {
    width: 100%;
    color: #575962;
    margin-bottom: 0;
    // min-height: 50vh;

    tr {
      width: 100%;
      th,
      td {
        padding: 15px 15px;
      }
      th {
        border-top: 0;
      }
      td {
        position: relative;
        vertical-align: middle;
      }
      &:hover {
        // td {

        // overflow: visible;
        //   white-space: unset;
        //   padding: 10px 1em;
        // }
      }
    }

    &.vertical-top {
      tr {
        td {
          vertical-align: top;
        }
      }
    }
  }
  .table-misc {
    .dropdown-toggle {
      background: #f0f6ff !important;
      border-radius: 5px;
      box-shadow: none !important;
      &:after {
        display: none;
      }
    }

    &.disabled {
      .dropdown-toggle {
        background: #ededed !important;
      }
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #ebedef;
  }
  // .table-footer {
  // }
  .page-link {
    padding: 0.45rem 0.85rem;
  }
  .page-rows {
    border: 0;
    width: 60px;
    font-weight: bold;
    margin-top: 3px;
    color: #666;
    &:focus,
    &:active,
    &.active {
      box-shadow: none;
    }
  }
}

.table-search-select {
  color: $white;
  background: $primary
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
}
// @media (max-width: 320px) {
// }

@media (min-width: 576px) {
  .modal-sm {
    max-width: 420px;
  }
}

.c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-header.c-header-fixed {
  box-shadow: $box-shadow;
}
html:not([dir='rtl']) .input-group > .form-control:not(:last-child),
html:not([dir='rtl']) .input-group > .custom-select:not(:last-child) {
  border-radius: 0.25rem;
}
// .input-group .form-control {
//   border-radius: 0.25rem !important;
// }
html:not([dir='rtl']) .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0.25rem;
}
.date-open {
  .modal-body {
    overflow: visible;
  }
}
.status {
  border-radius: 5px;
  background-color: $primary;
  padding: 5px 10px;
  font-size: 13px;
  color: $white;
  text-align: center;
  display: inline;
  white-space: nowrap;
  font-weight: $FONT_WEIGHT_SEMI_BOLD;

  &.block {
    display: block;
  }

  &.active,
  &.approved,
  &.interview-stage {
    background-color: $GREEN_10;
    color: $GREEN_60;
  }
  &.not-active,
  &.rejected {
    background-color: $RED_10;
    color: $RED_50;
  }
  &.process,
  &.pending,
  &.assessment-stage {
    background-color: $YELLOW_10;
    color: $YELLOW_70;
  }
  &.unvisited,
  &.blacklist {
    background-color: #efefef;
    color: #757575;
  }
  &.screening-cv-stage,
  &.unregistered {
    background-color: $PRIMARY_0;
    color: $PRIMARY_60;
  }
  &.offering-stage {
    background-color: $PURPLE_10;
    color: $PURPLE_70;
  }
  &.hired-stage {
    background-color: $PRIMARY_40;
    color: $NEUTRAL_10;
  }
}
.form-check-input {
  margin: 0 !important;
  position: relative;
  cursor: pointer;
}
.spinner {
  &.sk-flow {
    align-items: center;
  }
  &.white {
    --sk-color: white;
  }
  &.primary {
    --sk-color: #3583ee;
  }
  &.disabled {
    --sk-color: #c2c2c2;
  }
  &.defaultColor {
    --sk-color: #9e9e9e;
  }
}
.form-group {
  label {
    font-weight: 500;
    color: $NEUTRAL_90;
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
}
.DayPicker {
  .CalendarDay__selected {
    background: $primary;
    color: white;
    border-color: $primary;
    &:hover {
      background: $primary;
      border-color: $primary;
    }
  }
  .CalendarDay__selected_span {
    background: #75a4de;
    border: 1px double #6197da;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #88b1e3;
    border: 1px double #88b1e3;
    color: $white;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #6197da;
    border: 1px double #6197da;
  }
}
.DateRangePicker {
  .DateRangePickerInput {
    border-radius: 5px;
  }
  .DateInput {
    border-radius: 5px;
  }
  .DateInput_input {
    padding: 7px 11px 5px;
    // border-radius: 5px;
  }
  .DateInput_input__focused {
    border-bottom-color: $primary;
  }
}

.toaster {
  &.toaster-top-right {
    top: 10px;
  }
  .toast {
    .toast-header {
      font-weight: bold;
      padding: 0.5rem 0.75rem;
      .close {
        margin-top: -3px;
      }
    }
    &.primary {
      .toast-header {
        background-color: $primary;
        color: $white;
        border-bottom-color: $white;
        .close {
          color: $white;
        }
      }
      background-color: $primary;
      color: $white;
    }
  }
}
.component-wrapper {
  height: calc(100vh - 56px);
  overflow: hidden;
  .component-wrapper-left {
    padding: 20px 20px 0;
    border-right: 6px solid #efefef;
    height: 100%;
    overflow: auto;
  }
  .component-wrapper-right {
    padding: 20px 30px;
    height: 100%;
    overflow: auto;
  }
  .component-card {
    max-height: calc(100vh - 215px);
    overflow: auto;
    div {
      div {
        &:focus {
          outline: $bluelight auto 1px;
        }
      }
    }
  }
  .component {
    position: relative;
    border: 1px solid #eee;
    padding: 30px 15px 15px;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.1);
    max-width: 184px;
    font-size: 13.5px;
    margin: 1rem 0;
    cursor: grab;
    background-color: $white;
    &.disabled {
      background-color: #eaeaea;
      cursor: not-allowed !important;
    }
  }
}

.overlay {
  transition: opacity 0.15s linear;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-loading {
  .modal-loading-body {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 35px;
    z-index: 1051;
    border-radius: 5px;
    box-shadow: $box-shadow;
    display: none;
  }
  &.open {
    .overlay {
      display: block;
    }
    .modal-loading-body {
      display: block;
    }
  }
}

.toaster .toast {
  &.danger {
    background-color: $red;
    color: white;
    .toast-header {
      background-color: $red;
      color: white;
      .close {
        color: white;
      }
    }
  }

  &.success {
    background-color: $success;
    color: white;
    .toast-header {
      background-color: $success;
      color: white;
      .close {
        color: white;
      }
    }
  }
}
.action-input {
  border: 0;
  border-bottom: 1px solid $grey;
  padding: 10px 0;
  border-radius: 0;
  background-color: transparent;
  &:focus {
    box-shadow: none;
  }
  &.is-error {
    border-bottom-color: $danger;
  }
}

.autocomplete {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  background-color: $white;
  box-shadow: $box-shadow;
  border: 1px solid #e4e7ea;
  top: 120%;
  z-index: 100;
  .list {
    padding: 12px 15px;
    cursor: pointer;
  }
}

.autocomplete-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

$vs-checkbox-radio-border-width: 2px;
$vs-checkbox-radio-border-color: #999;
$vs-checkbox-box: 16px;
$vs-checkbox-box-sm: 11px;
$vs-checkbox-box-lg: 20px;

.vx-checkbox-con {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // margin-bottom: 2px;
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 200;
    cursor: pointer;
    top: 0;
    &:checked {
      ~ .vx-checkbox {
        transform: rotate(0deg);
        border-color: $primary;
        .vx-checkbox--check {
          transform: translate(0);
          background-color: $primary;
          .vx-icon {
            transform: translate(0);
            line-height: 1.2;
            opacity: 1;
          }
        }
        &.vx-checkbox-sm {
          .vx-checkbox--check .vx-icon {
            font-size: 0.75rem;
          }
        }
        &.vx-checkbox-lg {
          .vx-checkbox--check .vx-icon {
            font-size: 1.2rem;
          }
        }
      }
    }
    &:active:checked + .vx-checkbox {
      .vx-checkbox--check {
        transform: translate(3px);
        background-color: $primary;
        .vx-icon {
          opacity: 1;
          transform: translate(6px);
        }
      }
    }
    &:disabled {
      cursor: default;
      pointer-events: none;
      + .vx-checkbox {
        cursor: default;
        opacity: 0.5;
      }
    }
  }
  .vx-checkbox {
    cursor: pointer;
    position: relative;
    width: $vs-checkbox-box;
    height: $vs-checkbox-box;
    border-width: $vs-checkbox-radio-border-width;
    border-style: solid;
    border-color: $NEUTRAL_50;
    border-radius: 4px;
    transform: rotate(-90deg);
    transition: all 0.2s ease;
    overflow: hidden;
    margin-top: -1px;
    // margin-right: 0.5rem;
    &.vx-checkbox-sm {
      width: $vs-checkbox-box-sm;
      height: $vs-checkbox-box-sm;
    }
    &.vx-checkbox-lg {
      width: $vs-checkbox-box-lg;
      height: $vs-checkbox-box-lg;
    }
    .vx-checkbox--check {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      left: 0;
      transform: translate(100%);
      transform-origin: right;
      transition: all 0.2s ease;
      z-index: 10;
      .vx-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
      }
    }
  }
  .vx-checkbox-label {
    line-height: 1.2;
    flex: 1;
    margin-top: -2px;
  }
}

// .c-sidebar .c-sidebar-nav-link.c-active,
// .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
//   color: #fff;
//   background: rgba(255, 255, 255, 0.2);
// }

.c-sidebar-nav {
  background: #fafafa !important;
}
.c-sidebar,
.c-sidebar-brand {
  box-shadow: $box-shadow !important;
}
.c-sidebar .c-sidebar-nav-link {
  border-left: 4px solid transparent;
  margin: 5px 0;
}
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: $primary;
  background: $primary10 !important;
  border-color: transparent;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: transparent;
}
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: $primary;
}
.c-sidebar .c-sidebar-nav-title {
  color: $primary;
  font-weight: 400;
  opacity: 0.7;
  font-size: 12px;
  letter-spacing: 0.06em;
  padding-left: 25px;
}
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle,
.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: $primary;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgb(38, 106, 253)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
@media (hover: hover), not all {
  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background: $primary10 !important;
  }
  .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: $primary;
  }
  .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgb(38, 106, 253)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !important;
  }
}

.o-modal-header {
  background-color: white !important;
  color: black !important;
  font-weight: bold !important;
  .close {
    color: black !important;
  }
}
.o-modal-body {
  padding: 0;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}

.modal {
  &.fade .modal-dialog {
    transform: translateY(50%);
    top: 100%;
  }
  &.show .modal-dialog {
    transform: translateY(-60%);
    top: 50%;
  }
  .modal-title {
    font-size: 1rem;
    font-weight: bold;
  }
  .modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // border-bottom: 0;
    // padding-bottom: 0;
    .close {
    }
  }
  .modal-content {
    border-radius: 10px;
  }
  .modal-body {
    max-height: 70vh;
    overflow: auto;
  }
  .modal-footer {
    border-top: 0;
    z-index: 100;
    background-color: $white;
    // padding-top: 0;
    box-shadow: $box-shadow;
  }
}

.page-tabs {
  .page-tabs-header {
    padding: 0;
    overflow: auto;
    .page-tabs-header-item {
      display: inline-block;
      margin-right: 25px;
      padding: 0 7px 10px;
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
      position: relative;
      opacity: 0.3;
      text-align: center;
      white-space: nowrap;
      width: auto;
      color: #333;
      &.active,
      &:hover {
        opacity: 1;
        text-decoration: none;
        color: $primary;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          // transform: translateX(-50%);
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: $primary;
        }
      }
    }
  }
  .page-tabs-content {
  }
}

.c-wrapper {
  background: #fff;
}

@media (max-width: 576px) {
  .component-wrapper {
    overflow-x: auto;
  }
  .component-card {
    width: 100vw;
    &.right {
      width: 130vw;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input,
  select {
    font-size: 16px !important;
  }
  textarea {
    font-size: 12px !important;
  }
}

.ant-select-selector {
  height: 43px;
  border-radius: 5px;
}

.custom-multiselect {
  .ant-select-selector {
    height: auto;
    min-height: 43px;
    border-radius: 5px;
  }
}

// antd table override style
.ant-table-wrapper {
  .ant-pagination {
    margin: 16px !important;
  }

  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background-color: #fff !important;
        border-bottom: 2px solid #d8dbe0;
        font-weight: 600;
      }
    }
  }
}

.antd-custom-row {
  cursor: pointer;
  &.active {
    background-color: $primary10;
  }
}

// antd modal override
.ant-modal {
  &-header {
    border-radius: 10px 10px 0 0 !important;
  }

  &-content {
    border-radius: 10px !important;
  }
}

// antd notif override
.ant-notification {
  z-index: 1050 !important;
}

// antd checkbox
.custom-checkbox-group {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

// antd list
.custom-list {
  .ant-list-items {
    max-height: 300px;
    overflow-y: auto;
  }
}

// custom dropdown
.custom-dropdown {
  &__menu {
    visibility: hidden;
    transform: translateY(-2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
      z-index 0s linear 0.01s;
    opacity: 0;
    z-index: -1;
  }

  .active,
  &:focus,
  &:focus-within,
  &:hover {
    .custom-dropdown__menu {
      visibility: visible;
      transform: translateY(0%);
      opacity: 1;
      z-index: 1;
      transition-delay: 0s, 0s, 0.3s;
    }
  }
}

// custom menu
.custom-menu {
  padding: 0 !important;
  border-radius: 8px;
  &__item-group {
    .ant-dropdown-menu-item {
      padding: 0.5rem 1rem;
    }
    .ant-dropdown-menu-item-group-list {
      margin: auto;
    }
    .ant-dropdown-menu-item-group-title {
      background-color: $NEUTRAL_20;
      color: $NEUTRAL_70;
      font-weight: $FONT_WEIGHT_MEDIUM;
    }
  }
}

//
.apexcharts-tooltip.apexcharts-theme-light {
  border: none !important;
  background-color: transparent !important;
}
.custom-tooltip-box {
  background-color: rgba($NEUTRAL_100, 0.9);
  color: $white;
  border: 1px solid $NEUTRAL_100;

  position: relative;

  &__header {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $NEUTRAL_10;
  }

  &__body {
    padding: 0.5rem 1rem;
  }
}

// custom upload
.custom-antd-upload {
  &.block {
    .ant-upload.ant-upload-select {
      display: block !important;
    }
  }

  &.custom-upload-list {
    .ant-upload-list-item {
      margin-top: 0;
      margin-bottom: 1rem;
      height: 44px;

      .ant-upload-list-item-thumbnail {
        width: 32px;
        height: 32px;
        img {
          width: 32px;
          height: 32px;
        }
      }

      .anticon-delete {
        color: $RED_50;
      }
    }
  }
  &.upload-list-flex {
    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// tooltip anrd custom
.custom-tooltip {
  &.agent {
    background-color: rgba($NEUTRAL_10, 1);
  }
}

// custom antd collapse
.custom-antd-collapse {
  &.no-padding {
    .ant-collapse-item > .ant-collapse-header {
      padding: 1rem 0;
    }
  }

  &.rejected {
    .ant-collapse-item > .ant-collapse-header {
      border: 1px solid $RED_20;
      background-color: $RED_0;
      border-radius: 6px 6px 0 0 !important;
    }
    .ant-collapse-item > .ant-collapse-content {
      border: 1px solid $RED_20;
      border-radius: 0 0 6px 6px !important;
    }
  }

  &.hired {
    .ant-collapse-item > .ant-collapse-header {
      border: 1px solid $PRIMARY_20;
      background-color: $PRIMARY_0;
      border-radius: 6px 6px 0 0 !important;
    }
    .ant-collapse-item > .ant-collapse-content {
      border: 1px solid $PRIMARY_20;
      border-radius: 0 0 6px 6px !important;
    }
  }

  &.offering {
    .ant-collapse-item > .ant-collapse-header {
      border: 1px solid $PURPLE_30;
      background-color: $PURPLE_10;
      border-radius: 6px 6px 0 0 !important;
    }
    .ant-collapse-item > .ant-collapse-content {
      border: 1px solid $PURPLE_30;
      border-radius: 0 0 6px 6px !important;
    }
  }

  &.assessment_1,
  &.assessment_2,
  &.assessment_3 {
    .ant-collapse-item > .ant-collapse-header {
      border: 1px solid $YELLOW_20;
      background-color: $YELLOW_0;
      border-radius: 6px 6px 0 0 !important;
    }
    .ant-collapse-item > .ant-collapse-content {
      border: 1px solid $YELLOW_20;
      border-radius: 0 0 6px 6px !important;
    }
  }

  &.interview {
    .ant-collapse-item > .ant-collapse-header {
      border: 1px solid $GREEN_20;
      background-color: $GREEN_0;
      border-radius: 6px 6px 0 0 !important;
    }
    .ant-collapse-item > .ant-collapse-content {
      border: 1px solid $GREEN_20;
      border-radius: 0 0 6px 6px !important;
    }
  }

  &.screening_cv {
    .ant-collapse-item > .ant-collapse-header {
      border: 1px solid $PRIMARY_40;
      background-color: $PRIMARY_10;
      border-radius: 6px 6px 0 0 !important;
    }
    .ant-collapse-item > .ant-collapse-content {
      border: 1px solid $PRIMARY_40;
      border-radius: 0 0 6px 6px !important;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}

// antd custom radio group
.ant-radio-group {
  font-size: $TEXT_SMALL !important;
}
